import React from 'react';
import GuestLayout from '@/Layouts/GuestLayout';
import {Head, router} from '@inertiajs/react';
import Card, {CardTitle} from "@/Components/Card";
import Button, {ButtonScale, ButtonTheme} from "@/Components/Button";
import ConnectionButton from "@/Components/ConnectionButton";
import WalletConnector, {useWalletConnector} from "@/Components/WalletConnector";
import {PageProps} from "@/types";
import {getChain} from "@/wagmi";

interface AuthChallengePageProps extends PageProps {
    status: {
        message: string
    }
}

export default function WalletLogin(props: PageProps) {

    const {connectors, controller} = useWalletConnector({
        chain: getChain(props.networks.default),
        fetchChallenge: async (account, chainId) => new Promise((resolve, reject) => {
            return router.post(route('login.wallet.requestAuthMessage'), {
                address: account,
                chainId: chainId
            }, {
                onSuccess: ({props}: { props: Partial<AuthChallengePageProps> }) => {
                    if (props.status?.message) {
                        return resolve(props.status.message);
                    }

                    reject(new Error("Challenge request returned a malformed response"));
                },
                onError: (errors) => {
                    reject(errors['address'] || errors['chainId']);
                }
            })
        }),
        onSignature: (account, chainId, challenge, signature) => {
            return new Promise((resolve, reject) => router.post(route('login.wallet'), {
                address: account,
                signature: signature
            }, {
                onSuccess: () => resolve(),
                onError: (errors) => {
                    reject(errors['address'] || errors['signature']);
                }
            }))
        }
    });

    return (
        <GuestLayout>
            <Head title="Connect Wallet"/>

            <Card className="sm:max-w-lg mx-auto py-8">

                <div className="mb-8">
                    <CardTitle className="mb-2">Connect wallet</CardTitle>
                    <p className="text-stone-700 mb-4">Choose your preferred wallet to log in. You will be asked to sign an authentication message.</p>
                    <a href='https://ethereum.org/en/wallets/' rel="noreferrer noopener" target="_blank">
                        What is a wallet?
                    </a>
                </div>

                <div className="flex flex-col gap-2">

                    {connectors.map((connector) => (
                        <ConnectionButton key={connector.uid} connector={connector} onClick={controller.begin}
                                          disabled={controller.interacting}/>
                    ))}

                    <Button theme={ButtonTheme.Secondary} scale={ButtonScale.Large} href={route('login.email')}>
                        Connect using email
                    </Button>

                </div>
            </Card>

            <WalletConnector controller={controller}/>

        </GuestLayout>
    );
}
